<template>
    <cluji-card :title="title" @collapse="onCollapse">
        <div class="chartContainer" v-if="display">
            <component :is="component" :chart-data="data" v-if="data.datasets"/>
        </div>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import('@/components/ClujiCard');
    const LineChart = () => import('@/components/stats/LineChart');

    export default {
        name: "CardLineChart",
        components: {ClujiCard, LineChart},
        props: {
            title: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                default: 'Line'
            }
        },
        data: () => ({
            display: true
        }),
        computed: {
            component() {
                return () => import('@/components/stats/' + this.type + 'Chart');
            }
        },
        methods: {
            onCollapse(val) {
                this.display = val;
            }
        }
    }
</script>
